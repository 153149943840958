<template>
    <div class="wrapper-container">
<!--        <common-header>{{from == 'reset' ? '重置密碼' : '修改密碼'}}</common-header>-->
        <div class="register-container">
            <div>
                <div class="inp-container">
                    <div class="password-container" v-if="from !== 'reset'">
                        <span class="password-tip">旧密码:</span>
                        <div  class="wrapper wrapper-2 hide"><input max-length="20" v-model.trim="passwordObj.eye1" placeholder="请输入旧密码" class="inp inp-password" :type="eyeStatus['eye1'] ? 'password' : 'text'"></div>
                        <span @click="toggleEye(1)" class="eye" :class="eyeStatus.eye1 ? 'close-eye' : ''"></span>
                    </div>
                    <div class="password-container">
                        <span class="password-tip">新密码:</span>
                        <div  class="wrapper wrapper-2 hide"><input max-length="20" v-model.trim="passwordObj.eye2" placeholder="请输入新密码" class="inp inp-password" :type="eyeStatus['eye2'] ? 'password' : 'text'"></div>
                        <span @click="toggleEye(2)" class="eye" :class="eyeStatus.eye2 ? 'close-eye' : ''"></span>
                    </div>
                    <div class="tip password-tip">密码必须至少8个字符，而且同时包含字母和数字。</div>
                </div>
            </div>
            <div class="login" @click="certain">确认</div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
// import CommonHeader from '@/components/header.vue'
import {validatePassword} from '@/utils/tools'
export default {
    data() {
        return {
            isClose: false,
            passwordObj: {
                eye1: '',
                eye2: ''
            },
            eyeStatus: {
                eye1: false,
                eye2: false
            },
            eyeCopyPassword: {
                eye1: '12345678',
                eye2: ''
            },
            from: ''
        }
    },
    components: {
        // CommonHeader
    },
    computed: {
        ...mapState({
            realNameInfo: state => state.layout.realNameInfo
        })
    },
    mounted() {
        this.from = this.$route.query['from']
        console.log(this.from)
        console.log('this.realNameInfo,', this.realNameInfo)
        this.passwordObj['eye1'] = this.realNameInfo['password']
    },
    methods: {
        certain() {
            // 校验密码
            const {eye2} = this.passwordObj
            if(!validatePassword(eye2)) {
                this.$toast({
                    message: '请按照正确格式输入密码'
                })
                // return
            }
            // 重置密碼
            if(this.from == 'reset') {
                this.$store.dispatch('resetPassport', {
                    telephone: this.$route.query['phone'],
                    password: this.$getRsaCode(this.passwordObj.eye2)
                }).then(res => {
                    if(res.code == 200) {
                        this.$router.push('/login')
                    }
                })
                return
            }
            // 确认修改密码
            this.$store.dispatch('certain', {
                oldPassword: this.$getRsaCode(this.passwordObj.eye1),
                password: this.$getRsaCode(this.passwordObj.eye2) //新密码
            }).then(res => {
                // 登录成功跳回到个人信息界面
                // 密码修改成功 2秒后跳回个人信息页面
                if(res.code == 200) {
                    this.$toast({
                        message: this.from == 'reset' ? '重置密码成功' : '修改密码成功',
                        position: 'top'
                    })
                    setTimeout(() => {
                        if(this.from == 'reset') {
                            this.$router.push('/mine')
                        }else {
                            this.$router.push('/login')
                        }
                    }, 2000)
                }else {
                    this.$toast({
                        message: res.msg,
                        position: 'top'
                    })
                }

            })
        },
        toggleEye(num) {
            this.eyeStatus[`eye${num}`] = !this.eyeStatus[`eye${num}`]
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper-container {
        min-height: 100vh;
        background: #fff;
    }
    .register-container {
        width: 100%;
        padding: 80px 40px 0 35px;
        box-sizing: border-box;
        background: #fff;
        .to-register {
            color: #00C2C1;
            font-size: 16px;
            position: absolute;
            top: 22px;
            right: 22px;
        }
        .appName {
            font-size: 28px;
            color: #02BCBD;
            font-family: PingFangSC-bold;
            margin-bottom: 60px;
        }

        .tip {
            font-size: 18px;
            color: #ccc;
            font-family: PingFangSC-regular;
            text-align: left;
            height: 40px;
        }

        .inp-container {
            display: flex;
            flex-direction: column;

            .wrapper {
                display: flex;
                align-items: center;
                position: relative;
                &-1::before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: url('../../assets/images/login-user.png');
                    background-size: 100%;
                    position: absolute;
                }

                &-2::before {
                    content: '';
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/login-lock.png');
                    background-size: 100%;
                    position: absolute;
                }

                &.hide {
                    .inp {
                        // padding-left: 0;
                    }
                    &::before {
                        background: none;
                    }
                }

                .inp {
                    width: 300px;
                    height: 44px;
                    border-bottom: 1px solid #eee;
                    padding-left: 80px;
                    padding-right: 24px;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #101010;
                    box-sizing: border-box;
                }
            }

            .password-tip {
                font-size: 14px;
                // margin-top: 10px;
            }

            .password-container {
                display: flex;
                position: relative;
                align-items: center;
                .password-tip {
                    display: block;
                    width:80px;
                    flex-shrink: 0;
                    font-size: 16px;
                    color: #000;
                    position: absolute;
                    left: 0;
                    z-index: 100;
                    text-align: left;
                }
                .eye {
                    display: block;
                    width: 24px;
                    height: 24px;
                    background: url('../../assets/images/visibility.png');
                    background-size: 100%;
                    position: absolute;
                    right: 0;
                    top: 10px;
                    &.close-eye {
                        background: url('../../assets/images/unVisibility.png');
                        background-size: 100%;
                    }
                }
            }
        }

        .login {
            width: 210px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            color: #fff;
            background: #02BCBD;
            margin: 100px auto 0;
            border-radius: 20px;
        }

        .read {
            margin-top: 50px;
            display: flex;
            width: 300px;
            .choose {
                width: 20px;
                height: 20px;
                border: 1px solid #ccc;
                display: block;
                border-radius: 50%;
                margin-right: 2px;

                &.active {
                    background: url('../../assets/images/checked.png');
                    background-size: 100%;
                    border: none;
                }
            }

            .text {
                text-align: left;
                .protocol {
                    color: #02BCBD;
                }
            }
        }

    }
</style>
